import { inView, animate } from 'motion';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import './main.css';
import Navigation from './Blocks/HeaderNavigation/navigation';
import ScrollTopBtn from './js/components/scrollTopButton';
import CustomCursor from './js/components/customCursor';

Alpine.plugin(intersect);

window.Alpine = Alpine;
Alpine.start();

  const projectLists = document.querySelectorAll('.giantpeach-projectslist');
  if (projectLists.length) {
    import('./Blocks/ProjectsList/projectsList.js').then(({ default: ProjectList }) => {
      projectLists.forEach(el => {
          ProjectList(el);
      });
    });
  }
  
const domReady = () => {
  Navigation();
  const banner = document.querySelectorAll('.site-banner');
  const logos = document.querySelectorAll('.giantpeach-logos');
  const igfeed = document.querySelectorAll('.giantpeach-igfeed');
  const imageCarousels = document.querySelectorAll('.giantpeach-imagecarousel');
  const buttonScroll = document.querySelectorAll('.button-scroll');
  const slider = document.querySelectorAll('.giantpeach-slider');
  const maps = document.querySelectorAll('.giantpeach-map');

  /* Scroll up btn */
  const scrollTopBtn = document.querySelector('.scroll-up-btn');

  if (scrollTopBtn) {
    ScrollTopBtn(scrollTopBtn)
  }

  /* Cursor */
  CustomCursor();

  if (banner.length) {
    import('./Blocks/Banner/banner.js').then(({ default: Banner }) => {
      banner.forEach(el => {
        Banner(el);
      });
    });
  }

  if (slider.length) {
    import('./Blocks/Slider/slider.js').then(({ default: Slider }) => {
      slider.forEach(el => {
        Slider(el);
      });
    });
  }

  if (logos.length) {
    import('./Blocks/Logos/logos.js').then(({ default: LogosCarouselInstance }) => {
      logos.forEach(el => {
          LogosCarouselInstance(el);
      });
    });
  }

  if (igfeed.length) {
    import('./Blocks/IgFeed/igfeed.js').then(({ default: IgFeedCarouselInstance }) => {
      igfeed.forEach(el => {
          IgFeedCarouselInstance(el);
      });
    });
  }
  
  if (imageCarousels.length) {
    import('./Blocks/ImageCarousel/imageCarousel.js').then(({ default: imageCarouselInstance }) => {
      imageCarousels.forEach(el => {
          imageCarouselInstance(el);
      });
    });
  }

  if (maps.length) {
    import('./Blocks/Map/map.js').then(({ default: GpMap }) => {
      maps.forEach(el => {
          GpMap(el);
      });
    });
  }

  if (buttonScroll.length) {
    import('./Blocks/ButtonScroll/buttonScroll.js').then(({ default: ButtonScroll }) => {
      ButtonScroll();
    });
  }
  
  // Animate - in
  let transitionableBlocks = document.querySelectorAll('.transition-block');
  let inViewStop = inView(transitionableBlocks, ({ target }) => {
    animate(
      target,
      { opacity: 1, transform: 'none' },
      { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { transform: 'translateY(60px)' },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  }); 

  // Animate - mobile-tile / team
  if (window.innerWidth < 1024) {
    let transitionableTileBlocks = document.querySelectorAll('.transition-tile-block');
    inView(transitionableTileBlocks, ({ target }) => {
      target.classList.add('mobile-hover-active');

      return () => {
        target.classList.remove('mobile-hover-active');
      };
    }, { amount: 0.9 }); 
  }

  /* Animate only on load */
  document.addEventListener('block:load', () => {
    transitionableBlocks = document.querySelectorAll('.transition-block');

    inViewStop();
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: 'none' },
        { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );

      return () => {
        animate(
          target,
          { transform: 'translateY(60px)' },
          { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
        );
      };
    });
  });


  // Animate - Article content 
  let articleTransitionableBlocks = document.querySelectorAll('.giantpeach-articlecontent .article-content-blocks');
  articleTransitionableBlocks.forEach(el => {
    let elChildren = el.children;
    let articleInViewStop = inView(elChildren, ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: 'none' },
        { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );

      return () => {
        animate(
          target,
          { opacity: 0, transform: 'translateY(60px)' },
          { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
        );
      };
    });
  });
};

document.addEventListener('DOMContentLoaded', domReady);
