import { animate } from 'motion';

const CustomCursor = _ => {
    const customCursorAreas = document.querySelectorAll('.cursor-area');
    const cursor = document.querySelector('.cursor');

    const cursorMoveFunc = e => {
        cursor.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';

        if (e.target && e.target.nodeName == 'A') {
            document.body.classList.remove('cursor-active');
        } else {
            document.body.classList.add('cursor-active');
        }
    }

    customCursorAreas.forEach(section => {
        section.addEventListener('mouseenter', _ => {
            document.body.classList.add('cursor-active');
            document.addEventListener('mousemove', cursorMoveFunc, false);
        });


        section.addEventListener('mouseleave', _ => {
            document.body.classList.remove('cursor-active');
            document.removeEventListener('mousemove', cursorMoveFunc, false);
        });
    });

    document.addEventListener('cursor-active', function() {
        animate(cursor.querySelector('svg'), {
            transform: ["translate(-50%,-50%)", "translate(-35%, -50%)", "translate(-50%,-50%)"],
            opacity: [1, 0.3, 1]
        }, {
            duration: 0.4
        })
    });
};


export default CustomCursor;