import throttle from '../libs/throttle.js';

const ScrollTopButton = _ => {
	const button = document.querySelector('.scroll-up-btn');

	if (button) {
		button.addEventListener('click', _ => {
		    window.scrollTo({
		        top: 0,
		        behavior: "smooth"
		    });
		});
	}

	window.addEventListener("scroll", throttle(e=> {
		if (window.scrollY > (window.innerHeight * 0.3)) {
			button.classList.remove('opacity-0', 'invisible');
		} else {
			button.classList.add('opacity-0', 'invisible');
		}
	}));
}

export default ScrollTopButton;