import { animate, stagger, timeline } from 'motion';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const Navigation = () => {
  const navEl = document.querySelector('.nav');
  const navItemEls = document.querySelectorAll('.nav-item');
  const navText = document.querySelectorAll('.nav-text');
  const secondaryNavEls = document.querySelectorAll('.secondary-nav');
  const siteLogoEl = document.querySelector('.site-logo');
  const siteNavCta = document.querySelector('.site-nav-cta');
  const marquee = navEl.querySelector('.nav-marquee');
  const transition = 100;

  if (!navEl) {
    return;
  }

  document.addEventListener('nav:toggle', (e) => {
    if (e.detail) {
      // open
      openNav();
    } else {
      // close
      closeNav();
    }
  });

  const openNav = () => {
    disableBodyScroll(navEl.querySelector('.scroll-list'));

    timeline([
      [navEl, { x: 0 }, { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] }],

      [
        siteLogoEl,
        { filter: 'brightness(255)' },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1], at: '-0.3' },
      ],
      [
        siteNavCta,
        { filter: 'brightness(255)' },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1], at: '-0.4' },
      ],
      [
        navItemEls,
        { y: 0, opacity: 1 },
        { duration: 0.5, delay: stagger(0.1), easing: [0.25, 0.1, 0.25, 1], at: '-0.2' },
      ],
      [
        navText,
        { y: 0, opacity: 1 },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1], at: '<' },
      ],
    ]);
  };

  const closeNav = (duration = 0.5) => {
    enableBodyScroll(navEl.querySelector('.scroll-list'));

    timeline([
      [
        navEl,
        { x: '100%' },
        { duration: duration, easing: [0.25, 0.1, 0.25, 1] },
      ],
      [
        siteNavCta,
        { filter: 'brightness(1)' },
        { duration: 0.25, easing: [0.25, 0.1, 0.25, 1], at: 0 },
      ],
      [
        siteLogoEl,
        { filter: 'brightness(1)' },
        { duration: 0.25, easing: [0.25, 0.1, 0.25, 1], at: 0 },
      ],
      [
        navItemEls,
        { opacity: 0, y: '30px' },
        {
          duration: duration,
          easing: [0.25, 0.1, 0.25, 1],
        },
      ],
      [
        navText,
        { opacity: 0, y: '30px' },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1], at: '<' },
      ],
    ]);
  };

  closeNav(0);
};

export default Navigation;
